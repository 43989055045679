function getEnvironment() {
  if (!window || !window.location) {
    return 'development';
  }
  if (window.location.hostname === 'asfalisou.online') {
    return 'production';
  }
  if (window.location.hostname === 'tst.asfalisou.online') {
    return window.location.pathname.includes('/PR') ? 'pull-request' : 'test';
  }
  return 'development';
}

const environment = getEnvironment();

const EnvironmentProviderPlugin = {
  install(Vue) {
    Vue.prototype.$env = Vue.$env = environment;
  }
};

const Environment = {
  getEnvironment: getEnvironment
};

export { EnvironmentProviderPlugin, Environment };
