import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'

import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

import VueAnalytics from 'vue-analytics';

import App from './App.vue'
import store from './store/index';
import router from './router' 
import { EnvironmentProviderPlugin, Environment } from './plugins/environmentProviderPlugin';
import { commands } from './lib/vueAnalyticsCommands';
import VueLazyload from 'vue-lazyload'
 
Vue.use(VueLazyload, {
  attempt: 1,
  listenEvents: [ 'scroll' ]
})
Vue.use(EnvironmentProviderPlugin);

Vue.use(VueAnalytics, {
  id: 'UA-185880041-1',
  router,
  debug: {
    enabled: Environment.getEnvironment() !== 'production', // whether console statements are written
    sendHitTask: Environment.getEnvironment() === 'production' // whether the hits are sent to GA
  },
  commands
});

Vue.config.productionTip = false

new Vue({
  created () {
    
  },
  store,
  router,
  render: h => h(App),
}).$mount('#app')
