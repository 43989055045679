import Vue from 'vue'

import { 
    LayoutPlugin, 
    BButtonGroup, 
    BButton, 
    BCard, 
    BForm, 
    BFormGroup, 
    BFormInput, 
    BInputGroup, 
    BInputGroupPrepend,
    BModal,
    VBModal ,
    BCardText,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavItem,
    BNavbarNav,
    BSidebar,
    BInputGroupAppend,
    BCardGroup,
    BNavItemDropdown,
    BDropdownItem
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(LayoutPlugin)
Vue.component('BButtonGroup', BButtonGroup)
Vue.component('BButton', BButton)
Vue.component('BCard', BCard)
Vue.component('BForm', BForm)
Vue.component('BButton', BButton)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BFormInput', BFormInput)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BInputGroupPrepend', BInputGroupPrepend)
Vue.component('BModal', BModal)
Vue.directive('BModal', VBModal)
Vue.component('BCardText', BCardText)
Vue.component('BNavbar', BNavbar)
Vue.component('BNavbarBrand', BNavbarBrand)
Vue.component('BNavbarToggle', BNavbarToggle)
Vue.component('BNavItem', BNavItem)
Vue.component('BNavbarNav', BNavbarNav)
Vue.component('BSidebar', BSidebar)
Vue.component('BCollapse', BCollapse)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BCardGroup', BCardGroup)
Vue.component('BNavItemDropdown', BNavItemDropdown)
Vue.component('BDropdownItem', BDropdownItem)

