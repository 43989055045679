import cloneDeep from 'lodash.clonedeep';

const stateDefault = {
  chosenPackage: 0,
};

const mutations = {
  SET_CHOSEN_PACKAGE_NAME(state, { chosenPackage }) {
    const stateRef = state;
    stateRef.chosenPackage = chosenPackage;
  }
};

const actions = {
  setChosenPackage(store, chosenPackage) {
    store.commit('SET_CHOSEN_PACKAGE_NAME', { chosenPackage });
  },
};

const getters = {};

export const state = cloneDeep(stateDefault);

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};