import Vue from 'vue';
import Vuex from 'vuex';

import landingPage from './modules/landingPage';
import insurancePackages from './modules/insurancePackages';
import apiCalls from './modules/apiCalls';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    landingPage,
    insurancePackages,
    apiCalls
  },
  strict: true
});
