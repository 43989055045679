import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const routes = [
    {
        path:'/',
        name:'home',
        component: () => import(/* webpackChunkName: "Home-route" */ './components/Home.vue')
    },
    {
        path:'/blog', 
        name:'blog',
        component: () => import(/* webpackChunkName: "blog-route" */ './components/Blog/Blog.vue')
    },
    {
        path:'/blog-details/:title/:id', 
        name:'blog-details',
        component: () => import(/* webpackChunkName: "BlogDetails-route" */ './components/Blog/BlogDetails.vue'),
        meta: {
            sitemap: {
                slugs: [
                    {
                        id: 11111,
                        title: 'ΚΤΕΟ: Το πρόστιμο για καθυστέρηση και η Τροχαία',
                        priority:  1.0,
                    },
                    {
                        id: 'invalid-slug', 
                        title: 'another-post',
                    }
                ],
            }
        }
    },
    {
        path:'/companies', 
        name:'companies',
        component: () => import(/* webpackChunkName: "companies-route" */ './components/Companies/Companies.vue')
    },
    {
        path:'/faqs', 
        name:'faqs',
        component: () => import(/* webpackChunkName: "FAQs-route" */ './components/FAQs.vue')
    },
    {
        path:'/insurance/:insuranceType', 
        name:'insurance',
        component: () => import(/* webpackChunkName: "InsuranceDetails-route" */ './components/InsuranceDetails/InsuranceDetails.vue'),
        meta: {
            sitemap: {
                slugs: [
                    {
                        insuranceType: 'Car',
                        priority:  1.0,
                    },
                    {
                        insuranceType: 'MotorCycle',
                        priority:  1.0,
                    },
                    {
                        insuranceType: 'Home',
                        priority:  1.0,
                    }
                ],
            }
        }
    },
    {
        path:'/contactus', 
        name:'contactus',
        component: () => import(/* webpackChunkName: "contactus-route" */ './components/ContactUs.vue')
    },
    
    {
        path:'/aboutus', 
        name:'aboutus',
        component: () => import(/* webpackChunkName: "aboutus-route" */ './components/AboutUs.vue')
    },
    {
        path:'/index.html', 
        name:'Home',
        component: () => import(/* webpackChunkName: "Home-route" */ './components/Home.vue')
    },
    {
        path:'*', 
        name:'PageNotFound',
        component: () => import(/* webpackChunkName: "PageNotFound-route" */ './components/PageNotFound.vue')
    }
]

export default new Router({
    scrollBehavior () {
        return { x: 0, y: 0 };
    },
    routes
});

