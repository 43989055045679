<template>
  <div class="main">
    <Header />
    <router-view />
    <div v-if="this.$router.history.current.path === '/contactus'">
      <ContactFooter :chosenPackageValue="chosenPackage" />
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
const Header = () => import('./components/Header.vue');
const Footer = () => import('./components/Footer.vue');
const ContactFooter = () => import('./components/ContactFooter.vue');

export default {
  name: "App",
  components: {
    Header,
    Footer,
    ContactFooter,
  },
  data() {
    return{
      
    }
  },
  computed: {
    ...mapState('insurancePackages', [
      'chosenPackage',
    ]),
  },
  methods: {
  }
};
</script>

<style>
body {
  font-family: Roboto, sans-serif;
  margin: 0;
  background-image: url('assets/images/bg-pattern.png');
  padding: 0;
}
.main {
  overflow: hidden;
}
a {
  text-decoration: none;
}
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
