import cloneDeep from 'lodash.clonedeep';
import axios from 'axios';

// initial state
const stateDefault = {
    emailFormData: {},
    carMakes: [],
    carModels: [],
    motorCycleMakes: [],
    motorCycleModels: []
};

// mutations
const mutations = {
    SET_CAR_MAKES_DATA(state, { carMakes }) {
        const stateRef = state;
        stateRef.carMakes = carMakes;
    },
    SET_CAR_MODELS_DATA(state, { carModels }) {
        const stateRef = state;
        stateRef.carModels = carModels;
    },
    SET_MOTOR_CYCLE_MAKES_DATA(state, { motorCycleMakes }) {
        const stateRef = state;
        stateRef.motorCycleMakes = motorCycleMakes;
    },
    SET_MOTOR_CYCLE_MODELS_DATA(state, { motorCycleModels }) {
        const stateRef = state;
        stateRef.motorCycleModels = motorCycleModels;
    }
};

// actions
const actions = {
    async sendEmailFormData(store, emailFormData) {
        await axios.post('https://api.asfalisou.online/v1/email/contactus' , emailFormData)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(error);
            return false;
        })
    },
    async getCarMakesData(store) {
        let response = await axios.get('https://api.asfalisou.online/v1/car/makes');
        let carMakes = response.data.filteredResults || [];
        store.commit('SET_CAR_MAKES_DATA', { carMakes });
    },
    async getCarModelsData(store, carModel) {
        let response = await axios.get(`https://api.asfalisou.online/v1/car/make/models?make=${carModel}`);
        let carModels = response.data.models || [];
        store.commit('SET_CAR_MODELS_DATA', { carModels });
    },
    async getMotorCycleMakesData(store) {
        let response = await axios.get('https://api.asfalisou.online/v1/motorcycle/makes');
        let motorCycleMakes = response.data.filteredResults || [];
        store.commit('SET_MOTOR_CYCLE_MAKES_DATA', { motorCycleMakes });
    },
    async getMotorCycleModelsData(store, motorCycleModel) {
        let response = await axios.get(`https://api.asfalisou.online/v1/car/motorcycle/models?make=${motorCycleModel}`);
        let motorCycleModels = response.data.models || [];
        store.commit('SET_MOTOR_CYCLE_MODELS_DATA', { motorCycleModels });
    },
    async sendVehicleInfo(store, vehicleInfoData) {
        await axios.post('https://api.asfalisou.online/v1/email/vehicleForm' , vehicleInfoData)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(error);
            return false;
        })
    }
};

export const state = cloneDeep(stateDefault);

export default {
    namespaced: true,
    state,
    actions,
    mutations
};