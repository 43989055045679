import cloneDeep from 'lodash.clonedeep';

const stateDefault = {
  plateNumber: {
    'car' : '',
    'motorCycle': '',
    'truck': '',
    'home' : ''
  },
};

const mutations = {
  SET_VEHICLE_PLATE(state, { plateNumber, insuranceType }) {
    const stateRef = state;
    stateRef.plateNumber[insuranceType] = plateNumber;
  },

  RESET_PLATE_NUMBER(state) {
    const stateRef = state;
    stateRef.plateNumber = {
      'car' : '',
      'motorCycle': '',
      'truck': '',
      'home' : ''
    };
  }
};

const actions = {
  setVehiclePlates(store, payload) {
    const plateNumber = payload.vehiclePlates;
    const insuranceType = payload.insuranceType;
    store.commit('SET_VEHICLE_PLATE', { plateNumber, insuranceType });
  },

  resetPlateNumber(store) {
    store.commit('RESET_PLATE_NUMBER');
  }
};

const getters = {};

export const state = cloneDeep(stateDefault);

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};